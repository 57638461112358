<template>
  <div class="signup">
    <el-row type="flex" justify="center">
      <el-col :xs="20" :sm="16" :md="12" :lg="10" :xl="6" class="login-content">
        <router-link to="/">
          <img class="login-logo" alt="logo" src="/logo.png" height="60" />
        </router-link>
        <el-card class="login-card" shadow="hover" v-loading="loading">
          <div slot="header" class="signup-title">
            <span>{{ $t("signup.title") }}</span>
            <el-dropdown @command="handleCommand" style="float: right">
              <span class="el-dropdown-link">
                {{ siteIntl ? "International" : "中国站" }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cn">{{ "中国站" }}</el-dropdown-item>
                <el-dropdown-item command="kr">{{ "International" }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-form ref="form" :model="form" :rules="signupRules" label-width="auto">
            <el-form-item :label="$t('common.email')" prop="email" required>
              <el-tooltip class="item" effect="dark" :content="emailVerify.msg" :value="emailVerify.msg !== ''"
                :manual="true" placement="right">
                <el-input v-model="form.email" size="small" @focus="changeEmail" @blur="changeEmail"
                  :suffix-icon="emailIcon" autocomplete="off"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item :label="$t('common.username')" prop="userName" required>
              <el-tooltip class="item" effect="dark" :content="userNameVerify.msg" :value="userNameVerify.msg !== ''"
                :manual="true" placement="right">
                <el-input v-model="form.userName" size="small" @focus="changeUserName" @blur="changeUserName"
                  :suffix-icon="userNameIcon" autocomplete="username"></el-input>
              </el-tooltip>
            </el-form-item>
            <el-form-item :label="$t('common.password')" prop="password" key="password" required>
              <el-input v-model="form.password" size="small" show-password autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('signup.confirmPassword')" prop="confirmPassword" key="confirmPassword" required>
              <el-input v-model="form.confirmPassword" size="small" show-password
                autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('signup.phoneNo')" prop="phoneNo" required>
              <el-input v-model="form.phoneNo" size="small">
                <el-select v-model="form.phoneCountryCode" :filterable="true" slot="prepend" style="width: 80px;">
                  <el-option v-for="item in countryCodes" :label="'+' + item.key" :value="item.key" :key="item.key">{{
        "+" + item.key }}</el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('signup.company')" prop="company" required>
              <el-input v-model="form.company" size="small"></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('signup.area')" prop="area" required>
              <el-select v-model="form.area" size="small" class="area-select" popper-class="signup-popper">
                <template slot="reference">
                  <span class="option-arrow"></span>
                </template>
<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
  <div :style="{ textAlign: 'center' }">
    <svg-icon v-if="item.icon" :style="{ width: '20px', height: '20px', verticalAlign: '-5px', marginRight: '5px' }"
      :icon-class="item.icon" />
    <span>{{ item.label }}</span>
  </div>
</el-option>
</el-select>
</el-form-item> -->
            <el-form-item label-width="0">
              <el-button class="login-button" type="primary" size="small" @click="onSubmit">{{ $t("signup.signup")
                }}</el-button>
            </el-form-item>
            <el-form-item class="login-signup" label-width="0">
              <span class="login-info">{{ $t("signup.loginInfo") }}</span>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <ai-footer />
  </div>
</template>


<script>
import AiFooter from "@/components/AiFooter.vue";
import { mapState } from "vuex";
import { signup } from "@/api/user";
import { fetchCountryCodes } from "@/api/common";
import { signupVerify } from "@/api/user";

export default {
  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error(this.$t("signup.passwordNotMatch")));
      } else {
        callback();
      }
    };
    return {
      signupRules: {
        email: [
          {
            required: true,
            message: this.$t("signup.emailRequired"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("signup.emailFormat"),
            trigger: ["blur", "change"],
          }
        ],
        userName: [
          {
            required: true,
            message: this.$t("signup.userNameRequired"),
            trigger: "blur",
          },
          { min: 4, max: 20, message: this.$t("signup.userNameLength"), trigger: 'blur' },
        ],
        password: [
          {
            required: true,
            message: this.$t("signup.passwordRequired"),
            trigger: "blur",
          },
          { min: 6, max: 32, message: this.$t("signup.passwordLength"), trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: this.$t("signup.confirmPasswordRequired"), trigger: 'blur' },
          { validator: validateConfirmPassword, trigger: 'blur' },
        ],
        phoneNo: [
          {
            required: true,
            message: this.$t("signup.phoneNoRequired"),
            trigger: "blur",
          }
        ],
        company: [
          {
            required: true,
            message: this.$t("signup.companyRequired"),
            trigger: "blur",
          }
        ],
        // area: [
        //   {
        //     required: true,
        //     message: this.$t("signup.areaRequired"),
        //     trigger: "blur",
        //   }
        // ],
      },
      form: {
        email: "",
        userName: "",
        password: "",
        confirmPassword: "",
        phoneNo: "",
        phoneCountryCode: process.env.VUE_APP_ENV && process.env.VUE_APP_ENV.endsWith(".kr") ? 82 : 86,
        company: "",
        // area: "",
      },
      options: [
        {
          value: "cn",
          label: "China",
          icon: "zh"
        },
        {
          value: "kr",
          label: "Korea",
          icon: "ko"
        },
        {
          value: "",
          label: "Other",
        },
      ],
      loading: false,
      countryCodes: [],
      emailVerify: {
        status: 0,
        msg: ""
      },
      userNameVerify: {
        status: 0,
        msg: ""
      },
    };
  },
  mounted() {
    this.fetchCountryCodes();
  },
  methods: {
    fetchCountryCodes() {
      fetchCountryCodes().then(response => {
        if (response.success) {
          this.countryCodes = response.result;
        }
      })
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          signup(this.form)
            .then((response) => {
              this.loading = false;
              if (response.success) {
                // success
                this.$alert(
                  this.$t("signup.succSignup"),
                  this.$t("signup.succSignupInfo"),
                  {
                    confirmButtonText: "OK",
                    callback: () => {
                      this.$router.push({ path: "/signin" }).catch(() => { });
                    },
                  }
                );
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    changeEmail() {
      this.$refs.form.validateField("email", (error) => {
        if (error) {
          // invalid
          this.emailVerify = { status: 0, msg: "" };
        } else {
          // check email exist
          this.emailVerify = { status: 2, msg: "" };
          this.signupVerify({ mode: "email", value: this.form.email });
        }
      });
    },
    changeUserName() {
      this.$refs.form.validateField("userName", (error) => {
        if (error) {
          // invalid
          this.userNameVerify = { status: 0, msg: "" };
        } else {
          // check userName exist
          this.userNameVerify = { status: 2, msg: "" };
          this.signupVerify({ mode: "userName", value: this.form.userName });
        }
      });
    },
    signupVerify(data) {
      signupVerify(data).then(response => {
        if (response.success) {
          if (data.mode === 'email') {
            this.emailVerify = response.result;
            if (this.emailVerify.msgCode) {
              this.emailVerify.msg = this.$t("signup." + this.emailVerify.msgCode);
            }
          } else if (data.mode === 'userName') {
            this.userNameVerify = response.result;
            if (this.userNameVerify.msgCode) {
              this.userNameVerify.msg = this.$t("signup." + this.userNameVerify.msgCode);
            }
          }
        }
      })
    },
    handleCommand(command) {
      if (command === 'kr') {
        window.location.href = 'https://console.fplusai.com/signup'
      } else {
        window.location.href = 'https://console.fplusai.com.cn/signup'
      }
    }
  },
  name: "Signup",
  components: {
    AiFooter,
  },
  computed: {
    ...mapState({}),
    emailIcon() {
      if (this.emailVerify.status === 1) {
        return "el-icon-success"
      } else if (this.emailVerify.status === -1) {
        return "el-icon-error"
      } else if (this.emailVerify.status === 2) {
        return "el-icon-loading"
      }
      return ""
    },
    userNameIcon() {
      if (this.userNameVerify.status === 1) {
        return "el-icon-success"
      } else if (this.userNameVerify.status === -1) {
        return "el-icon-error"
      } else if (this.userNameVerify.status === 2) {
        return "el-icon-loading"
      }
      return ""
    },
  },
};
</script>

<style lang="scss" scoped>
.signup {
  text-align: center;

  .login-card {
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;

    .signup-title {
      text-align: left;
    }

    .login-signup {
      margin: 0 -10px 10px;
    }

    .login-signup /deep/ .el-form-item__content {
      line-height: 20px;
      color: gray;
    }

    .login-info {
      margin-right: 5px;
      vertical-align: middle;
    }

    .area-select {
      width: 100%;
    }
  }

  .login-content {
    min-height: calc(100vh - 50px);
  }

  .login-logo {
    margin: 45px 0 30px;
  }

  .login-button {
    width: 100%;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.signup-popper .popper__arrow {
  left: 50% !important;
}
</style>